<template>
  <div class="personal-center">
    <Header />
    <search-bar />
    <div class="list-manage need-manage">
    <section class="list-manage-table">
      <div class="tabs-bar">
        <div
          class="tabs-item"
          :class="{ selected: index == tabsCurrent }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsChange(index)"
        >
          {{ item }}
        </div>
      </div>
    </section>

  </div>
    <div class="container w-1200" v-if="user!=null">
     
      <div class="sidebar">
      
        <div class="user-info">
          <div class="avatar" v-if="!user.consumer_head"><img src="~@/assets/images/temp/temp_04.png" alt=""><i class="btn-edit-avatar" @click="$router.push('/personalCenter/personalInfo').catch(()=>{})"></i></div>
          <div class="avatar" v-else><img :src="user.consumer_head" alt=""><i class="btn-edit-avatar" @click="$router.push('/personalCenter/personalInfo').catch(()=>{})"></i></div>
          <div class="name">{{user.consumer_name}}</div>
          <div class="account">账号：{{user.id}}</div>
          <div class="tag">
            <span class="tag-item" @click="$router.push({ name: 'supplierApply', params: { mobile: user.mobile } })" v-if="user.is_supplier == 1 ">供应商</span>
            <span class="tag-item" @click="$router.push({name:'projectApply', params: { mobile: user.mobile }} )" v-if="user.is_procet == 1 ">项目方</span>
            <span class="tag-item" :class="{'disabled':true}" v-if="user.is_procet === 3" @click="onclick()">项目方(待审核)</span>
            <span class="tag-item" :class="{'disabled':true}" v-if="user.is_supplier === 3" @click="onclick()">供应商(待审核)</span>
            <span class="tag-item" :class="{'disabled':true}" v-if="user.is_procet === 2 || user.is_procet === 4" @click="$router.push({name:'projectApply', params: { mobile: user.mobile }} )">{{user.is_procet === 2 ? '申请项目方' : '项目方(审核失败)'}}</span>
            <span class="tag-item" :class="{'disabled':true}" v-if="user.is_supplier === 2 || user.is_supplier === 4" @click="$router.push({ name: 'supplierApply', params: { mobile: user.mobile } })">{{user.is_supplier === 2 ? '申请供应商' : '供应商(审核失败)'}}</span>
          </div>
          <div class="btn-quit" @click="loginOut()"><i class="f-icon icon-quit"></i>退出登录</div>
        </div>
        <div class="nav">
          <div class="nav-item" @click="gomyorder(),go(11)" :class="{'selected':show[11]}"  v-if="user.is_procet == 1">我的订单</div>
          <div class="nav-item" @click="gomydevice(),go(1)" :class="{'selected':show[1]} " v-if="user.is_supplier == 1">我的设备</div> <!--  -->
          <div class="nav-item" @click="goneedmanage(),go(2)" :class="{'selected':show[2]}" v-if="user.is_procet == 1">需求管理</div>
          <div class="nav-item" @click="goordermanage(),go(3)" :class="{'selected':show[3]}" v-if="user.is_procet == 1">订单管理</div>
          <div class="nav-item" @click="gorentalorder(),go(4)" :class="{'selected':show[4]}"   v-if="user.is_supplier == 1">出租订单</div>
          <i class="divider"></i>
          <div class="nav-item" @click="gosettlementManage(),go(5)" :class="{'selected':show[5]}" v-if="user.is_procet == 1 || user.is_supplier == 1">结算管理</div>
          <div class="nav-item" @click="gobillmanage(),go(6)" :class="{'selected':show[6]}" v-if="user.is_procet == 1">发票管理</div>
          <div class="nav-item" @click="gobillManage(),go(7)" :class="{'selected':show[7]}" v-if="user.is_procet == 1">抬头管理</div>
          <div class="nav-item" @click="gocontractmanage(),go(8)" :class="{'selected':show[8]}">合同管理</div>
          <i class="divider"></i>
          <div class="nav-item" @click="gomycollection(),go(9)" :class="{'selected':show[9]}">我的收藏</div>
          <div class="nav-item" @click="gopersonalinfo(),go(10)" :class="{'selected':show[10]}">个人信息</div>
        </div>

      </div>
      <div class="router-wrap">
        <router-view></router-view>
      </div>
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
  import Header from "@/components/header"
  import SearchBar from "@/components/searchBar"
  import Footer from "@/components/footer"
  import RightNav from "@/components/rightNav"
  //import {user_find} from '@/api/contract'
import { getuser } from "@/api/user"
import kits from "@/kits"
  export default {
    components: {
      Header,
      SearchBar,
      Footer,
      RightNav
    },
    data() {
      return {
        tabsCurrent: 0,
      tabsList: ["个人首页","消息"],
        user:{
          consumer_head:''
        },
        show:[false,false,false,false,false,false,false,false,false,false,false],
        url: '',
      };
    },
    created() {

     if(this.$route.params.tabsCurrent==1){
      this.tabsCurrent=1
     }
       
      
      this.user = getuser();
      console.log("===查看this.user",this.user)
      if(this.user==null){
        this.$router.push({path:'/home'});
        return
      }
      if(this.user.consumer_head) {
        this.user.consumer_head = kits.img.url(this.user.consumer_head)
      }
      this.url = this.$route.fullPath
      this.go()
      // this.getinfo()
    },
    methods: {
      tabsChange(index) {
      this.tabsCurrent = index;
      if(this.tabsCurrent ==1){
        this.$router.push({path:'/personalCenter/personalMessage'});
      }else if(this.user.is_supplier == 1){
        this.$router.push({path:'/personalCenter/rentalOrder'});
      }else{
        this.$router.push({path:'/personalCenter/myOrder'});
      }
      },
      gomyorder(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/myOrder'});
      },
      gomydevice(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/myDevice'});
      },
      goneedmanage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/needManage'});
      },
      goordermanage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/orderManage'});
      },
      gorentalorder(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/rentalOrder'});
      },
      gosettlementManage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/settlementManage'});
      },
      gobillmanage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/applyBill'});
      },
      gocontractmanage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/contractManage'});
      },
      gomycollection(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/myCollection'});
      },
      gopersonalinfo(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/personalInfo'});
      },
      gobillManage(){
        this.tabsCurrent=0
        this.$router.push({path:'/personalCenter/billManage'});
      },
      onclick(){
        this.$message.info("您的申请已在审核中");
      },
      loginOut(){
        if(localStorage.getItem("token")) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
        this.$router.push({name: 'login'})
      },
      go(index) {
        if(index) {
          for(let i =0 ;i < this.show.length;i++) {
            this.show[i] = false
          }
          this.show[index] = true
        }
      },
    }
  }
</script>

<style scoped>
.list-manage .list-manage-table .tabs-bar {
    display: flex;
    padding: 0 204px;
}
.personal-center .container {
    display: flex;
    padding-top: 26px;
    padding-bottom: 50px;
}
</style>



      <!-- <el-button type="primary" class="login-btn" @click="$router.push('/personalCenter/Statistics').catch(()=>{})">登录</el-button>
      <el-button type="primary" class="login-btn" @click="$router.push('/personalCenter/demo').catch(()=>{})">登录</el-button> -->
